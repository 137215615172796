<template>
  <div class="colors block block--white block--rounded">
    <div class="colors__block">
      <div class="colors__title">
        Основные настройки:
      </div>
      <div class="block__row">
        <div class="block__col">
          <div class="block__title">
            Основной цвет темы
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="theme.activeColor"
              @input="setSetting({
                path: ['common.theme', 'activeColor'],
                value: $event,
              })"
            />
          </div>
        </div>
        <div class="block__col">
          <div class="block__title">
            Основной цвет темы при наведении
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="theme.unActiveColor"
              @input="setSetting({
                path: ['common.theme', 'unActiveColor'],
                value: $event,
              })"
            />
          </div>
        </div>
        <div class="block__col">
          <div class="block__title">
            Вторичный цвет темы
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="theme.secondColor"
              @input="setSetting({
                path: ['common.theme', 'secondColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="colors__block">
      <div class="colors__title">
        Уведомления
      </div>
      <div class="block__row">
        <div class="block__col">
          <div class="block__title">
            Цвет info
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="notification.infoColor"
              @input="setSetting({
                path: ['common.notification', 'infoColor'],
                value: $event,
              })"
            />
          </div>
        </div>
        <div class="block__col">
          <div class="block__title">
            Цвет success
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="notification.successColor"
              @input="setSetting({
                path: ['common.notification', 'successColor'],
                value: $event,
              })"
            />
          </div>
        </div>
        <div class="block__col">
          <div class="block__title">
            Цвет warning
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="notification.warningColor"
              @input="setSetting({
                path: ['common.notification', 'warningColor'],
                value: $event,
              })"
            />
          </div>
        </div>
        <div class="block__col">
          <div class="block__title">
            Цвет error
          </div>
          <div class="block__field">
            <esmp-color-picker
              :value="notification.errorColor"
              @input="setSetting({
                path: ['common.notification', 'errorColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="colors__block">
      <div class="colors__title">
        Кнопки
      </div>
      <div class="colors__button">
        <div class="block__title">
          Кнопка основного вида
        </div>
        <div class="colors__example">
          <esmp-button :style="primaryButtonStyle" size="small">
            Base
          </esmp-button>
          <esmp-button :style="primaryButtonHoverStyle" size="small">
            Hover
          </esmp-button>
          <esmp-button
            :style="primaryButtonDisabledStyle"
            size="small"
            disabled
          >
            Disabled
          </esmp-button>
        </div>
        <div class="block__row">
          <div class="block__col block__field">
            <esmp-color-picker
              :value="mainButtons.textColor"
              placeholder="Цвет текста"
              @input="setSetting({
                path: ['common.buttons', 'main', 'textColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="mainButtons.backgroundColor"
              placeholder="Цвет фона (base)"
              @input="setSetting({
                path: ['common.buttons', 'main', 'backgroundColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="mainButtons.backgroundActionColor"
              placeholder="Цвет фона при наведении"
              @input="setSetting({
                path: ['common.buttons', 'main', 'backgroundActionColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="mainButtons.backgroundDisabledColor"
              placeholder="Цвет фона блокирования (disabled)"
              @input="setSetting({
                path: ['common.buttons', 'main', 'backgroundDisabledColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
      <div class="colors__button">
        <div class="block__title">
          Кнопка с обводкой (outline)
        </div>
        <div class="colors__example">
          <esmp-button
            :style="outlineButtonStyle"
            size="small"
            view="outline"
          >
            Base
          </esmp-button>
          <esmp-button
            :style="outlineButtonHoverStyle"
            size="small"
            view="outline"
          >
            Hover
          </esmp-button>
        </div>
        <div class="block__row">
          <div class="block__col block__field">
            <esmp-color-picker
              :value="outlineButtons.textColor"
              placeholder="Цвет текста"
              @input="setSetting({
                path: ['common.buttons', 'outline', 'textColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="outlineButtons.backgroundColor"
              placeholder="Цвет фона при наведении (hover)"
              @input="setSetting({
                path: ['common.buttons', 'outline', 'backgroundColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="outlineButtons.frameColor"
              placeholder="Цвет рамки (base)"
              @input="setSetting({
                path: ['common.buttons', 'outline', 'frameColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="outlineButtons.frameActionColor"
              placeholder="Цвет рамки при наведении (hover)"
              @input="setSetting({
                path: ['common.buttons', 'outline', 'frameActionColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
      <div class="colors__button">
        <div class="block__title">
          Кнопка без обводки (ghost)
        </div>
        <div class="colors__example">
          <esmp-button
            :style="ghostButtonStyle"
            size="small"
            view="ghost"
          >
            Base
          </esmp-button>
          <esmp-button
            :style="ghostButtonHoverStyle"
            size="small"
            view="ghost"
          >
            Hover
          </esmp-button>
        </div>
        <div class="block__row">
          <div class="block__col block__field">
            <esmp-color-picker
              :value="ghostButtons.textColor"
              placeholder="Цвет текста (base)"
              @input="setSetting({
                path: ['common.buttons', 'ghost', 'textColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="ghostButtons.textActionColor"
              placeholder="Цвет текста при наведении (hover)"
              @input="setSetting({
                path: ['common.buttons', 'ghost', 'textActionColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
      <div class="colors__button">
        <div class="block__title">
          Кнопка только текст (function)
        </div>
        <div class="colors__example">
          <esmp-button
            :style="functionButtonStyle"
            size="small"
            view="function"
          >
            Base
          </esmp-button>
          <esmp-button
            :style="functionButtonHoverStyle"
            size="small"
            view="function"
          >
            Hover
          </esmp-button>
        </div>
        <div class="block__row">
          <div class="block__col block__field">
            <esmp-color-picker
              :value="functionButtons.textColor"
              placeholder="Цвет текста (base)"
              @input="setSetting({
                path: ['common.buttons', 'function', 'textColor'],
                value: $event,
              })"
            />
          </div>
          <div class="block__col block__field">
            <esmp-color-picker
              :value="functionButtons.textActionColor"
              placeholder="Цвет текста при наведении (hover)"
              @input="setSetting({
                path: ['common.buttons', 'function', 'textActionColor'],
                value: $event,
              })"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('colours')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Hub from '@/plugins/event-hub';

export default {
  name: 'Colors',
  computed: {
    ...mapState('portalStyle', ['settings']),
    theme() {
      return this.settings.common.theme;
    },
    notification() {
      return this.settings.common.notification;
    },
    mainButtons() {
      return this.settings.common.buttons.main;
    },
    outlineButtons() {
      return this.settings.common.buttons.outline;
    },
    ghostButtons() {
      return this.settings.common.buttons.ghost;
    },
    functionButtons() {
      return this.settings.common.buttons.function;
    },

    // styles
    primaryButtonStyle() {
      return {
        color: this.mainButtons.textColor,
        background: this.mainButtons.backgroundColor,
      };
    },
    primaryButtonHoverStyle() {
      return {
        color: this.mainButtons.textColor,
        background: this.mainButtons.backgroundActionColor,
      };
    },
    primaryButtonDisabledStyle() {
      return {
        color: this.mainButtons.textColor,
        background: this.mainButtons.backgroundDisabledColor,
      };
    },

    outlineButtonStyle() {
      return {
        color: this.outlineButtons.textColor,
        borderColor: this.outlineButtons.frameColor,
      };
    },
    outlineButtonHoverStyle() {
      return {
        color: this.outlineButtons.textColor,
        borderColor: this.outlineButtons.frameActionColor,
        background: this.outlineButtons.backgroundColor,
      };
    },

    ghostButtonStyle() {
      return {
        color: this.ghostButtons.textColor,
      };
    },
    ghostButtonHoverStyle() {
      return {
        color: this.ghostButtons.textActionColor,
      };
    },

    functionButtonStyle() {
      return {
        color: this.functionButtons.textColor,
      };
    },
    functionButtonHoverStyle() {
      return {
        color: this.functionButtons.textActionColor,
      };
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['theme', 'notification', 'buttons'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки цветовой гаммы успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
};
</script>

<style lang="scss">
.colors {
  &__block {
    margin-bottom: 30px;
  }
  &__title {
    font-weight: bold;
    margin-bottom: 16px;
  }
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }
  .block__col {
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__example {
    margin-bottom: 16px;
    display: flex;
    gap: 16px;
    pointer-events: none;
  }
  &__button {
    margin-bottom: 20px;
    border: 1px solid $border-color-base;
    border-radius: $base-border-radius;
    padding: 20px;
  }
}
</style>
