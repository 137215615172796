var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"colors block block--white block--rounded"},[_c('div',{staticClass:"colors__block"},[_c('div',{staticClass:"colors__title"},[_vm._v(" Основные настройки: ")]),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Основной цвет темы ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.theme.activeColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.theme', 'activeColor'],
              value: $event,
            })}}})],1)]),_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Основной цвет темы при наведении ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.theme.unActiveColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.theme', 'unActiveColor'],
              value: $event,
            })}}})],1)]),_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Вторичный цвет темы ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.theme.secondColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.theme', 'secondColor'],
              value: $event,
            })}}})],1)])])]),_c('div',{staticClass:"colors__block"},[_c('div',{staticClass:"colors__title"},[_vm._v(" Уведомления ")]),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Цвет info ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.notification.infoColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.notification', 'infoColor'],
              value: $event,
            })}}})],1)]),_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Цвет success ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.notification.successColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.notification', 'successColor'],
              value: $event,
            })}}})],1)]),_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Цвет warning ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.notification.warningColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.notification', 'warningColor'],
              value: $event,
            })}}})],1)]),_c('div',{staticClass:"block__col"},[_c('div',{staticClass:"block__title"},[_vm._v(" Цвет error ")]),_c('div',{staticClass:"block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.notification.errorColor},on:{"input":function($event){return _vm.setSetting({
              path: ['common.notification', 'errorColor'],
              value: $event,
            })}}})],1)])])]),_c('div',{staticClass:"colors__block"},[_c('div',{staticClass:"colors__title"},[_vm._v(" Кнопки ")]),_c('div',{staticClass:"colors__button"},[_c('div',{staticClass:"block__title"},[_vm._v(" Кнопка основного вида ")]),_c('div',{staticClass:"colors__example"},[_c('esmp-button',{style:(_vm.primaryButtonStyle),attrs:{"size":"small"}},[_vm._v(" Base ")]),_c('esmp-button',{style:(_vm.primaryButtonHoverStyle),attrs:{"size":"small"}},[_vm._v(" Hover ")]),_c('esmp-button',{style:(_vm.primaryButtonDisabledStyle),attrs:{"size":"small","disabled":""}},[_vm._v(" Disabled ")])],1),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.mainButtons.textColor,"placeholder":"Цвет текста"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'main', 'textColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.mainButtons.backgroundColor,"placeholder":"Цвет фона (base)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'main', 'backgroundColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.mainButtons.backgroundActionColor,"placeholder":"Цвет фона при наведении"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'main', 'backgroundActionColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.mainButtons.backgroundDisabledColor,"placeholder":"Цвет фона блокирования (disabled)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'main', 'backgroundDisabledColor'],
              value: $event,
            })}}})],1)])]),_c('div',{staticClass:"colors__button"},[_c('div',{staticClass:"block__title"},[_vm._v(" Кнопка с обводкой (outline) ")]),_c('div',{staticClass:"colors__example"},[_c('esmp-button',{style:(_vm.outlineButtonStyle),attrs:{"size":"small","view":"outline"}},[_vm._v(" Base ")]),_c('esmp-button',{style:(_vm.outlineButtonHoverStyle),attrs:{"size":"small","view":"outline"}},[_vm._v(" Hover ")])],1),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.outlineButtons.textColor,"placeholder":"Цвет текста"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'outline', 'textColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.outlineButtons.backgroundColor,"placeholder":"Цвет фона при наведении (hover)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'outline', 'backgroundColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.outlineButtons.frameColor,"placeholder":"Цвет рамки (base)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'outline', 'frameColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.outlineButtons.frameActionColor,"placeholder":"Цвет рамки при наведении (hover)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'outline', 'frameActionColor'],
              value: $event,
            })}}})],1)])]),_c('div',{staticClass:"colors__button"},[_c('div',{staticClass:"block__title"},[_vm._v(" Кнопка без обводки (ghost) ")]),_c('div',{staticClass:"colors__example"},[_c('esmp-button',{style:(_vm.ghostButtonStyle),attrs:{"size":"small","view":"ghost"}},[_vm._v(" Base ")]),_c('esmp-button',{style:(_vm.ghostButtonHoverStyle),attrs:{"size":"small","view":"ghost"}},[_vm._v(" Hover ")])],1),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.ghostButtons.textColor,"placeholder":"Цвет текста (base)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'ghost', 'textColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.ghostButtons.textActionColor,"placeholder":"Цвет текста при наведении (hover)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'ghost', 'textActionColor'],
              value: $event,
            })}}})],1)])]),_c('div',{staticClass:"colors__button"},[_c('div',{staticClass:"block__title"},[_vm._v(" Кнопка только текст (function) ")]),_c('div',{staticClass:"colors__example"},[_c('esmp-button',{style:(_vm.functionButtonStyle),attrs:{"size":"small","view":"function"}},[_vm._v(" Base ")]),_c('esmp-button',{style:(_vm.functionButtonHoverStyle),attrs:{"size":"small","view":"function"}},[_vm._v(" Hover ")])],1),_c('div',{staticClass:"block__row"},[_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.functionButtons.textColor,"placeholder":"Цвет текста (base)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'function', 'textColor'],
              value: $event,
            })}}})],1),_c('div',{staticClass:"block__col block__field"},[_c('esmp-color-picker',{attrs:{"value":_vm.functionButtons.textActionColor,"placeholder":"Цвет текста при наведении (hover)"},on:{"input":function($event){return _vm.setSetting({
              path: ['common.buttons', 'function', 'textActionColor'],
              value: $event,
            })}}})],1)])])]),_c('div',{staticClass:"block__footer block__actions"},[_c('esmp-button',{attrs:{"view":"outline"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")]),_c('esmp-button',{attrs:{"view":"outline"},on:{"click":function($event){return _vm.setDefault('colours')}}},[_vm._v(" Вернуть базовые настройки ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }